import { useRef, useState, useCallback, useEffect, useMemo } from 'react'
import { createContainer } from '@blue-agency/react-utils'
import { useInterviewRecordingPlayerControl } from './useInterviewRecordingPlayerControl'
import {
  InterviewPinningObject,
  InterviewRecordingFragment,
  InterviewRecordingObject,
  ShownFragment,
} from '../types'
import { useInterviewRecordingToken } from '@/hooks/useInterviewRecordingToken'
import { useLocation } from 'react-router'

type Props = {
  interviewPinnings: InterviewPinningObject[]
  interviewRecording: InterviewRecordingObject
}
export const useInterviewRecordingPlayer = (props?: Props) => {
  if (!props) throw new Error('props should be set')

  const { interviewGuid } = useInterviewRecordingToken()

  const {
    currentTime,
    duration,
    handleVideoPlay,
    isPlaying,
    fullScreenHandle,
    handleVideoPause,
    isMuted,
    volume,
    handleMuteToggle,
    handleSeek,
    handleVolumeChange,
    handleFullscreenRequest,
    handleFullscreenExitRequest,
    playbackRate,
    handleChangePlaybackRate,
    playbackRateOptions,
    handleInitialPlay,
    seekTrigger,
    handleSeekTimeAndPlayVideo,
  } = useInterviewRecordingPlayerControl({
    interviewRecording: props.interviewRecording,
  })
  const [mainFragmentVideoUrl, setMainFragmentVideoUrl] = useState<
    string | undefined
  >(undefined)

  const [isError, setIsError] = useState(false)
  // TODO: isLoadingを追加してローディング中に再生するのを待つ

  const allFragmentsRef = useRef<{
    interviewers: InterviewRecordingFragment[]
    interviewees: InterviewRecordingFragment[]
  }>({
    interviewers: props.interviewRecording.interviewersList.reduce<
      InterviewRecordingFragment[]
    >((acc, current) => [...acc, ...current.fragmentsList], []),
    interviewees: props.interviewRecording.intervieweesList.reduce<
      InterviewRecordingFragment[]
    >((acc, current) => [...acc, ...current.fragmentsList], []),
  })

  const shownInterviewerFragmentsRef = useRef<InterviewRecordingFragment[]>([])
  const shownIntervieweeFragmentsRef = useRef<InterviewRecordingFragment[]>([])

  const mainFragment = useMemo(() => {
    return [
      ...shownIntervieweeFragmentsRef.current,
      ...shownInterviewerFragmentsRef.current,
    ].find((fragment) => fragment.videoUrl === mainFragmentVideoUrl)
  }, [mainFragmentVideoUrl])

  const assignRecording = useCallback(() => {
    const currentInterviewerFragments = getCurrentFragments(
      currentTime,
      allFragmentsRef.current.interviewers
    )
    const currentIntervieweeFragments = getCurrentFragments(
      currentTime,
      allFragmentsRef.current.interviewees
    )

    shownInterviewerFragmentsRef.current = currentInterviewerFragments
    shownIntervieweeFragmentsRef.current = currentIntervieweeFragments

    const exitsMainFragment = [
      ...currentIntervieweeFragments,
      ...currentInterviewerFragments,
    ].some((fragment) => fragment.videoUrl === mainFragmentVideoUrl)

    if (!exitsMainFragment) {
      const nextMainFragment = getDefaultMainFragment(
        currentInterviewerFragments,
        currentIntervieweeFragments
      )

      if (nextMainFragment) {
        setMainFragmentVideoUrl(nextMainFragment.videoUrl)
      } else {
        setMainFragmentVideoUrl(undefined)
      }
    }
  }, [currentTime, mainFragmentVideoUrl])

  useEffect(() => {
    props.interviewRecording.cookiesList.forEach((cookie) => {
      document.cookie = `${cookie.key}=${cookie.value};domain=${cookie.domain};path=/;secure;samesite=none;`
    })
  }, [props.interviewRecording.cookiesList])

  useEffect(() => {
    assignRecording()
  }, [assignRecording, currentTime])

  // ctを受けとっているときは別ダブで開いたときなので自動再生する
  const search = useLocation().search
  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const ct = searchParams.get('ct')
    if (ct) {
      handleInitialPlay()
    }
  }, [handleInitialPlay, search])

  const handleHlsError = useCallback(() => {
    setIsError(true)
    handleVideoPause()
  }, [handleVideoPause])

  const handleMainFragmentSelect = useCallback((fragment: ShownFragment) => {
    setMainFragmentVideoUrl(fragment.fragment.videoUrl)
  }, [])

  const shownInterviewerFragments = shownInterviewerFragmentsRef.current.map(
    (fragment) => mapToShownFragment(fragment, mainFragmentVideoUrl)
  )

  const shownIntervieweeFragments = shownIntervieweeFragmentsRef.current.map(
    (fragment) => mapToShownFragment(fragment, mainFragmentVideoUrl)
  )

  const interviewPinnings =
    props.interviewPinnings.length !== 0 ? props.interviewPinnings : undefined

  useEffect(() => {
    const listener = (e: MessageEvent<any>) => {
      handleSeekTimeAndPlayVideo(e.data)
    }

    window.addEventListener('message', listener)
    return () => window.removeEventListener('message', listener)
  }, [handleSeekTimeAndPlayVideo])

  return {
    interviewGuid,
    shownInterviewerFragments,
    shownIntervieweeFragments,
    mainFragment,
    currentTime,
    duration,
    volume,
    isPlaying,
    fullScreenHandle,
    isMuted,
    handleMainFragmentSelect,
    handleVideoPlay,
    handleVideoPause,
    handleMuteToggle,
    handleHlsError,
    handleVolumeChange,
    handleSeek,
    handleFullscreenRequest,
    handleFullscreenExitRequest,
    isError,
    playbackRate,
    handleChangePlaybackRate,
    playbackRateOptions,
    handleInitialPlay,
    seekTrigger,
    interviewPinnings,
  }
}

export const InterviewRecordingPlayerContainer = createContainer(
  useInterviewRecordingPlayer
)

function mapToShownFragment(
  fragment: InterviewRecordingFragment,
  mainFragmentVideoUrl: string | undefined
): ShownFragment {
  return {
    id: fragment.videoUrl,
    isMain: mainFragmentVideoUrl === fragment.videoUrl,
    fragment: fragment,
  }
}

function getCurrentFragments(
  currentTime: number,
  allFragments: InterviewRecordingFragment[]
): InterviewRecordingFragment[] {
  return allFragments.filter((fragment) => {
    return (
      fragment.startTimeOffset <= currentTime &&
      currentTime <= fragment.stopTimeOffset
    )
  })
}

function getDefaultMainFragment(
  interviewerFragments: InterviewRecordingFragment[],
  intervieweeFragments: InterviewRecordingFragment[]
): InterviewRecordingFragment | undefined {
  if (interviewerFragments[0]) {
    return interviewerFragments[0]
  }

  if (intervieweeFragments[0]) {
    return intervieweeFragments[0]
  }

  return undefined
}
