import React from 'react'
import styled from 'styled-components'
import { InterviewRecordingComponent } from '@/components/InterviewRecordingComponent'
import { provide } from '@blue-agency/react-utils'
import { ImVideoPlayerBffContainer } from '@/hooks/useImVideoPlayerBff'
import { InterviewRecordingForEmbedContainer } from '@/hooks/useInterviewRecordingForEmbed'
import { EncodingContent } from './content/EncodingContent'
import { useInterviewRecordingToken } from '../../hooks/useInterviewRecordingToken'
import { useTitle } from '@/hooks/useTitle'
import { ExpiredContent } from './content/ExpiredContent'
import { DeletedContent } from './content/DeletedContent'
import { FailedContent } from './content/FailedContent'
import { NotInterviewFinishedContent } from './content/NotInterviewFinishedContent'

export const EmbeddedInterviewRecordingPlayerPage: React.FCX = () => {
  const { isExpired } = useInterviewRecordingToken()

  useTitle('Web面接 録画データ')

  if (isExpired) {
    return <ExpiredContent />
  } else {
    return <TokenAlivePage />
  }
}

const TokenAlivePage: React.FCX = provide(
  InterviewRecordingForEmbedContainer,
  ImVideoPlayerBffContainer
)((props) => {
  const { interviewRecording, status, interviewPinnings } =
    InterviewRecordingForEmbedContainer.useContainer()

  switch (status) {
    case 'encoded':
      if (!interviewRecording) throw new Error('Not found interviewRecording')
      return (
        <Wrapper className={props.className}>
          <InterviewRecordingComponent
            interviewRecording={interviewRecording}
            interviewPinnings={interviewPinnings}
          />
        </Wrapper>
      )
    case 'deleted':
      return <DeletedContent />
    case 'encoding':
      return <EncodingContent />
    case 'failed':
      return <FailedContent />
    case 'not_finished':
      return <NotInterviewFinishedContent />
    default:
      throw new Error('unexpected status')
  }
})

const Wrapper = styled.div`
  width: 100vw;
  height: 100%;
`
