import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { Spinner } from '@/components/Spinner'

type PresenterRef = HTMLVideoElement
type Props = {
  className?: string
  isLoading: boolean
}

export const RecordingVideo = forwardRef<PresenterRef, Props>((props, ref) => {
  return (
    <Wrapper className={props.className}>
      {props.isLoading && (
        <SpinnerOverlay>
          <Spinner color="white" />
        </SpinnerOverlay>
      )}
      <Video playsInline ref={ref} />
    </Wrapper>
  )
})

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`
const SpinnerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`
const Video = styled.video`
  height: 100%;
  width: 100%;
`
