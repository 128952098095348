import React, { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import { theme } from '@blue-agency/rogue'
import { InterviewRecordingPlayerContainer } from './hooks/useInterviewRecordingPlayer'
import { PlayOverlay as _PlayOverlay } from '@/components/PlayOverlay'

type Props = {
  videoArea: ReactNode
  usersArea: ReactNode
}

export const InterviewRecordingLayout: React.FCX<Props> = ({
  className,
  videoArea,
  usersArea,
}) => {
  const { isPlaying, handleInitialPlay } =
    InterviewRecordingPlayerContainer.useContainer()
  const [isNotPlayedYet, setIsNotPlayedYet] = useState(true)

  useEffect(() => {
    if (isPlaying && isNotPlayedYet) {
      setIsNotPlayedYet(false)
    }
  }, [isNotPlayedYet, isPlaying])

  return (
    <Wrapper className={className}>
      <PlayOverlay onPlay={handleInitialPlay} active={isNotPlayedYet} />
      <VideoArea>{videoArea}</VideoArea>
      <UsersArea>{usersArea}</UsersArea>
    </Wrapper>
  )
}

export const usersAreaWidth = 240

const Wrapper = styled.div`
  position: relative;
  display: flex;
`
const PlayOverlay = styled(_PlayOverlay)`
  ${Wrapper} & {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`
const VideoArea = styled.div`
  background: ${theme.color.black[1]};
  ${Wrapper} & {
    width: calc(100% - ${usersAreaWidth}px);
  }
`
const UsersArea = styled.div`
  ${Wrapper} & {
    height: 100%;
  }
  width: ${usersAreaWidth}px;
  overflow-x: hidden;
  overflow-y: scroll;
  background: ${theme.color.gray[4]};
`
