import React from 'react'
import styled from 'styled-components'

type Props = {
  size?: number
  color?: string
}

export const Spinner: React.FCX<Props> = ({
  className,
  size = 32,
  color = 'black',
}) => {
  return (
    <Wrapper className={className} size={size} color={color}>
      <div className="spinner" />
    </Wrapper>
  )
}

const Wrapper = styled.div<Required<Props>>`
  .spinner {
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border: 2px solid ${({ color }) => color};
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 1.25s linear infinite;
  }

  @keyframes spinner-border {
    to {
      transform: rotate(360deg);
    }
  }
`
