import React from 'react'
import styled from 'styled-components'
import { InterviewRecordingPlayerContainer } from '../hooks/useInterviewRecordingPlayer'
import { RecordingVideos } from '../UsersArea/RecordingVideos'

export const UsersArea: React.FCX = ({ className }) => {
  const { shownIntervieweeFragments, shownInterviewerFragments } =
    InterviewRecordingPlayerContainer.useContainer()
  return (
    <Wrapper className={className}>
      <InterviewerVideos
        userType="interviewer"
        shownFragments={shownInterviewerFragments}
      />
      <IntervieweeVideos
        userType="interviewee"
        shownFragments={shownIntervieweeFragments}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`
const InterviewerVideos = styled(RecordingVideos)`
  ${Wrapper} & {
    margin-bottom: 30px;
  }
`
const IntervieweeVideos = styled(RecordingVideos)``
