import { createContainer } from '@blue-agency/react-utils'
import { useRef, useCallback, useMemo } from 'react'
import { ImVideoPlayerBffServicePromiseClient } from '@/pb/v2/im_video_player_bff/im_video_player_bff_service_grpc_web_pb'
import {
  GetInterviewRecordingRequest,
  GetStudioRecordingRequest,
} from '@/pb/v2/im_video_player_bff/im_video_player_bff_service_pb'
import { CustomGrpcError, logRpcErr, checkRetryError } from '@/utils'
import retry from 'async-retry'

const MAX_RETRY_COUNT = 5

const useImVideoPlayerBff = () => {
  const hostname = process.env.REACT_APP_API_HOST
  if (!hostname) throw new Error('hostname not found')
  const clientRef = useRef(new ImVideoPlayerBffServicePromiseClient(hostname))

  /**
   * gRPC-Web Dev Tools の設定
   * MEMO: 読み込まれたときに1度だけ実行したいが、useEffectを使うと
   * useCallbackで定義した関数の方が先に実行されてしまう
   * 一番最初に実行するためにuseMemoを使っている
   * @see https://github.com/SafetyCulture/grpc-web-devtools
   */
  useMemo(() => {
    if (process.env.REACT_APP_GRPC_WEB_DEVTOOLS_ENABLED === 'true') {
      // @ts-ignore
      const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {})
      enableDevTools([clientRef.current])
    }
  }, [])

  const getInterviewRecording = useCallback(
    async ({ token }: { token: string }) => {
      const client = clientRef.current
      const req = new GetInterviewRecordingRequest()
      req.setToken(token)
      const retrier = async (bail: (e: Error) => void) =>
        await client.getInterviewRecording(req).catch((err) => {
          const { shouldRetry } = checkRetryError(err)
          const customErr = new CustomGrpcError(err)
          if (!shouldRetry) {
            return bail(customErr) as never
          }
          logRpcErr('getInterviewRecording', customErr)
          throw customErr
        })
      return await retry(retrier, { retries: MAX_RETRY_COUNT })
    },
    []
  )

  const getHallRecording = useCallback(async ({ token }: { token: string }) => {
    const client = clientRef.current
    const req = new GetStudioRecordingRequest()
    req.setToken(token)
    const retrier = async (bail: (e: Error) => void) =>
      await client.getStudioRecording(req).catch((err) => {
        const { shouldRetry } = checkRetryError(err)
        const customErr = new CustomGrpcError(err)
        if (!shouldRetry) {
          return bail(customErr) as never
        }
        logRpcErr('getHallRecording', customErr)
        throw customErr
      })
    return await retry(retrier, { retries: MAX_RETRY_COUNT })
  }, [])

  return {
    getInterviewRecording,
    getHallRecording,
  }
}

export const ImVideoPlayerBffContainer = createContainer(useImVideoPlayerBff)
