import React from 'react'
import styled, { css } from 'styled-components'
import { theme } from '@blue-agency/rogue'

type Props = {
  volume: number
  isMuted: boolean
  onVolumeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const VolumeRange: React.FCX<Props> = ({
  className,
  onVolumeChange,
  volume,
  isMuted,
}) => (
  <Input
    className={className}
    type="range"
    min={0}
    max={1}
    step={0.01}
    value={isMuted ? 0 : volume}
    onChange={onVolumeChange}
  />
)

const Input = styled.input`
  -webkit-appearance: none;
  height: 5px;
  width: 80px;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  ${({ value }) => {
    const percent = Math.floor(100 * Number(value))
    return css`
      background: linear-gradient(
        to right,
        ${theme.color.white[1]} 0%,
        ${theme.color.white[1]} ${percent}%,
        ${theme.color.gray[3]} ${percent}%,
        ${theme.color.gray[3]} 100%
      );
    `
  }}
`
