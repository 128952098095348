import * as grpcWeb from 'grpc-web'
import { Comlink } from '@blue-agency/comlink'

type GrpcError = {
  message: string
  code: number
  metadata: grpcWeb.Metadata
}

export class CustomGrpcError extends Error {
  code: number
  metadata: grpcWeb.Metadata
  constructor(err: GrpcError) {
    super(err.message)
    this.name = 'CustomGrpcError'
    this.code = err.code
    this.metadata = err.metadata
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    }
  }
}

export const logRpcErr = (rpcName: string, customErr: CustomGrpcError) => {
  Comlink.push({
    type: 'system_activity',
    group: 'im-video-player-front',
    action: 'rpc_error',
    metadata: {
      name: rpcName,
      message: customErr.message ?? '',
      metadata: JSON.stringify(customErr.metadata) ?? '',
      code: customErr.code.toString() ?? '',
    },
  })
}

export const checkRetryError: (err: GrpcError) => { shouldRetry: boolean } = (
  err
) => {
  switch (err.code) {
    case grpcWeb.StatusCode.INVALID_ARGUMENT:
      return { shouldRetry: false }
    case grpcWeb.StatusCode.PERMISSION_DENIED:
      return { shouldRetry: false }
    case grpcWeb.StatusCode.UNAUTHENTICATED:
      return { shouldRetry: false }
    case grpcWeb.StatusCode.RESOURCE_EXHAUSTED:
      return { shouldRetry: false }
    default:
      return { shouldRetry: true }
  }
}
