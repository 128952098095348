import React, { Suspense, useEffect } from 'react'
import '@/assets/reset.css'
import '@/assets/theme.css'
import { Router, Route, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import {
  provide,
  CacheContainer,
  WindowSizeContainer,
} from '@blue-agency/react-utils'
import { ActivityIndicatorOverlay, theme } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { BrowserContainer } from '@/hooks/useBrowser'
import { UnsupportedBrowser } from './components/UnsupportedBrowser'
import { href } from './constants/href'
import { CustomGrpcError } from '@/utils'
import { cssBrowserSelector } from '@/cssBrowserSelector'
import { useTracking } from './hooks/useTracking'
import { path } from './constants/path'
import { EmbeddedInterviewRecordingPlayerPage } from './pages/EmbeddedInterviewRecordingPlayerPage'
import { EmbeddedHallRecordingPlayerPage } from './pages/EmbeddedHallRecordingPlayerPage'
import styled from 'styled-components'
import { ErrorComponent } from './components/ErrorComponent'
const history = createBrowserHistory()

if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    beforeSend(
      event: Sentry.Event,
      hint?: Sentry.EventHint
    ): PromiseLike<Sentry.Event | null> | Sentry.Event | null {
      // TODO: タイムアウト等sentryに通知不要なエラーは無視する
      if (hint && hint.originalException instanceof CustomGrpcError) {
        if (!event.tags) {
          event.tags = {}
        }
        event.tags.grpc_status_code = hint.originalException.code.toString()
        if (!event.extra) {
          event.extra = {}
        }
        event.extra.CustomGrpcError = hint.originalException
      }
      return event
    },
  })
}

const App: React.FC = provide(
  BrowserContainer,
  WindowSizeContainer,
  CacheContainer
)(() => {
  useTracking(history)
  const windowSize = WindowSizeContainer.useContainer()
  const { isInternetExplorer } = BrowserContainer.useContainer()

  useEffect(() => {
    cssBrowserSelector(navigator.userAgent)
  }, [])

  if (isInternetExplorer) {
    return (
      <UnsupportedBrowser
        windowSize={windowSize}
        href={href.recommendedEnvironment}
      />
    )
  }
  return (
    <Router history={history}>
      <Sentry.ErrorBoundary fallback={ErrorComponent}>
        <Wrapper>
          <Suspense fallback={<ActivityIndicatorOverlay />}>
            <Switch>
              <Route
                exact
                path={path.embedInterviewRecordingPlayer}
                component={EmbeddedInterviewRecordingPlayerPage}
              />
              <Route
                exact
                path={path.embedHallRecordingPlayer}
                component={EmbeddedHallRecordingPlayerPage}
              />
              <Route path="*" component={ErrorComponent} />
            </Switch>
          </Suspense>
        </Wrapper>
      </Sentry.ErrorBoundary>
    </Router>
  )
})

export default App

const Wrapper = styled.div`
  height: 100vh;
  background-color: ${theme.color.gray[5]};
`
