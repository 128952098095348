import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useReducer,
} from 'react'
import { InterviewRecordingObject } from '../types'
import { Comlink } from '@blue-agency/comlink'
import { useFullScreenHandle } from 'react-full-screen'
import { PlaybackRateOption } from '../Controls/PlaybackRate'
import { useLocation } from 'react-router'

const SYNC_INTERVAL_MS = 300

const playbackRateOptions: PlaybackRateOption[] = [
  {
    rate: 1,
    label: '1x (標準)',
  },
  {
    rate: 1.25,
    label: '1.25x',
  },
  {
    rate: 1.5,
    label: '1.5x',
  },
  {
    rate: 1.75,
    label: '1.75x',
  },
  {
    rate: 2,
    label: '2x',
  },
]

type Props = {
  interviewRecording: InterviewRecordingObject
}

export const useInterviewRecordingPlayerControl = ({
  interviewRecording,
}: Props) => {
  const { duration } = interviewRecording
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)

  const [currentTime, setCurrentTime] = useState<number>(() => {
    const ct = searchParams.get('ct')
    const num = Number(ct)
    if (!num) return 0
    return num
  })
  const [volume, setVolume] = useState(0.5)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(true)
  const fullScreenHandle = useFullScreenHandle()
  const playOnceRef = useRef(false)
  const [playbackRate, setPlaybackRate] = useState(1.0)
  const playbackRateRef = useRef(1.0)
  const [isPlayedOnce, setIsPlayedOnce] = useState(false)
  // NOTE: seekされたイベントを伝播させたいだけなので、カウントしていることに意味はない
  const [seekTrigger, onSeek] = useReducer((x: any) => x + 1, 0)

  const handleChangePlaybackRate = useCallback((rate: number) => {
    setPlaybackRate(rate)
    playbackRateRef.current = rate
  }, [])

  const timeoutRef = useRef<number>()
  const prevTimeRef = useRef<number | undefined>(undefined)

  const tickCurrentTime = useCallback(() => {
    timeoutRef.current = window.setInterval(
      () =>
        setCurrentTime((prevCurrentTime) => {
          const now = performance.now()

          const elapsedTime =
            prevTimeRef.current === undefined
              ? SYNC_INTERVAL_MS
              : now - prevTimeRef.current

          prevTimeRef.current = now

          const currentTime =
            prevCurrentTime + (elapsedTime * playbackRateRef.current) / 1000

          // NOTE: ビデオの長さが11秒のとき、再生終了した時点で currentTime が 11.12 などになっていることがあり、
          //  その場合、再生終了したときにすべてのビデオが非表示になる
          //  currentTime が duration を超えたときは duration を返すようにする
          return Math.min(duration, currentTime)
        }),
      SYNC_INTERVAL_MS
    )
  }, [duration])

  const stopCurrentTime = useCallback(() => {
    timeoutRef.current && clearInterval(timeoutRef.current)
    prevTimeRef.current = undefined
  }, [])

  const playVideo = useCallback(() => {
    tickCurrentTime()

    setIsPlaying(true)
  }, [tickCurrentTime])

  const pauseVideo = useCallback(() => {
    stopCurrentTime()

    setIsPlaying(false)
  }, [stopCurrentTime])

  useEffect(() => {
    if (duration <= currentTime) {
      pauseVideo()
      return
    }
  }, [currentTime, pauseVideo, duration])

  const handleVideoPlay = useCallback(() => {
    if (isPlaying) return

    if (!playOnceRef.current) {
      // TODO: iframe になり、複数のサービスから呼び出されることになる
      //  document.referrer ではなく、もっと直接 どのサービスの、どの面接かをサーバーからもらうべき
      Comlink.push({
        type: 'system_activity',
        group: 'video_player',
        action: 'play_recording_video',
        targetName: 'referrer',
        targetIdStr: document.referrer,
      })

      playOnceRef.current = true
    }

    if (duration <= currentTime) {
      setCurrentTime(0)
      playVideo()
      return
    }
    playVideo()
  }, [isPlaying, duration, currentTime, playVideo])

  const handleInitialPlay = useCallback(() => {
    if (isPlayedOnce) return
    setIsPlayedOnce(true)
    setIsMuted(false)
    handleVideoPlay()
  }, [handleVideoPlay, isPlayedOnce])

  const handleVideoPause = useCallback(() => {
    if (isPlaying) {
      pauseVideo()
    }
  }, [isPlaying, pauseVideo])

  const handleSeek = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(e.target.value)
      stopCurrentTime()
      setCurrentTime(value)
      onSeek()
      if (isPlaying) tickCurrentTime()
    },
    [isPlaying, tickCurrentTime, stopCurrentTime]
  )

  const handleMuteToggle = useCallback(() => {
    if (volume === 0) return

    setIsMuted(!isMuted)
  }, [isMuted, volume])

  const handleVolumeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(e.target.value)

      setVolume(value)
      setIsMuted(value === 0)
    },
    []
  )

  const handleFullscreenRequest = useCallback(() => {
    // iframeがネストしている場合は別タブで開く
    // ref: https://blue-agency.atlassian.net/browse/INQCL-1722
    if (window.parent !== window.top) {
      window.open(
        `${window.location.origin}${window.location.pathname}?ct=${currentTime}`
      )
      pauseVideo()
      return
    }
    try {
      fullScreenHandle.enter()
    } catch (e) {
      Comlink.push({
        type: 'system_activity',
        group: 'video_player',
        action: 'failed_to_enter_fullscreen_in_interview_recording',
        metadata: {
          errorMessage: e.message ?? '',
        },
      })
      alert('全画面表示に対応していません。')
    }
  }, [fullScreenHandle, currentTime, pauseVideo])

  const handleFullscreenExitRequest = useCallback(() => {
    try {
      fullScreenHandle.exit()
    } catch (e) {
      Comlink.push({
        type: 'system_activity',
        group: 'video_player',
        action: 'failed_to_exit_fullscreen_in_interview_recording',
        metadata: {
          errorMessage: e.message ?? '',
        },
      })
      alert('全画面表示の解除に失敗しました。')
    }
  }, [fullScreenHandle])

  const handleSeekTimeAndPlayVideo = useCallback(
    (seekTime: number) => {
      stopCurrentTime()
      setCurrentTime(seekTime)
      onSeek()
      if (!isPlayedOnce) {
        setIsMuted(false)
        setIsPlayedOnce(true)
      }
      playVideo()
    },
    [stopCurrentTime, onSeek, isPlayedOnce, playVideo]
  )

  return {
    currentTime,
    duration,
    volume,
    isPlaying,
    fullScreenHandle,
    isMuted,
    handleVideoPlay,
    handleVideoPause,
    handleMuteToggle,
    handleVolumeChange,
    handleSeek,
    handleFullscreenRequest,
    handleFullscreenExitRequest,
    playbackRate,
    handleChangePlaybackRate,
    playbackRateOptions,
    handleInitialPlay,
    seekTrigger,
    handleSeekTimeAndPlayVideo,
  }
}
