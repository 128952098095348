import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Icon, Txt, theme } from '@blue-agency/rogue'
import { InterviewRecordingPlayerContainer } from '../hooks/useInterviewRecordingPlayer'
import { VolumeRange } from '../Controls/VolumeRange'
import { PlaybackRate } from './PlaybackRate'

export const Controls: React.FCX = ({ className }) => {
  const {
    handleVideoPlay,
    handleVideoPause,
    currentTime,
    duration,
    isPlaying,
    isMuted,
    fullScreenHandle,
    handleMuteToggle,
    handleFullscreenRequest,
    handleFullscreenExitRequest,
    playbackRate,
    handleChangePlaybackRate,
    playbackRateOptions,
  } = InterviewRecordingPlayerContainer.useContainer()
  const timeLabel = useMemo(() => {
    return toHms(currentTime) + ' / ' + toHms(duration)
  }, [currentTime, duration])
  return (
    <Wrapper className={className}>
      <Left>
        {!isPlaying && (
          <IconWrapper onClick={handleVideoPlay}>
            <Icon name="play" size="m" />
          </IconWrapper>
        )}
        {isPlaying && (
          <IconWrapper onClick={handleVideoPause}>
            <Icon name="pause" size="m" />
          </IconWrapper>
        )}
        <TimeLabelTxt size="xs" color={theme.color.white[1]}>
          {timeLabel}
        </TimeLabelTxt>
      </Left>
      <Right>
        <VolumeRange />
        <IconWrapper onClick={handleMuteToggle}>
          {isMuted ? (
            <Icon name="mute" size="m" />
          ) : (
            <Icon name="volume" size="m" />
          )}
        </IconWrapper>
        <PlaybackRate
          rate={playbackRate}
          options={playbackRateOptions}
          onChange={handleChangePlaybackRate}
        />
        {!fullScreenHandle.active && (
          <IconWrapper onClick={handleFullscreenRequest}>
            <Icon name="fullscreen" size="m" />
          </IconWrapper>
        )}
        {fullScreenHandle.active && (
          <IconWrapper onClick={handleFullscreenExitRequest}>
            <Icon name="exit-fullscreen" size="m" />
          </IconWrapper>
        )}
      </Right>
    </Wrapper>
  )
}

const toHms = (seconds: number) => {
  const sec = Math.floor(seconds) % 60
  const min = Math.floor(seconds / 60) % 60
  const hour = Math.floor(seconds / 3600)
  return [
    ('00' + hour).slice(-2),
    ('00' + min).slice(-2),
    ('00' + sec).slice(-2),
  ].join(':')
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
const Left = styled.div`
  display: flex;
  align-items: center;
`
const Right = styled.div`
  ${Wrapper} & {
    margin-left: auto;
  }
  display: flex;
  align-items: center;
`
const TimeLabelTxt = styled(Txt)`
  ${Left} & {
    margin-left: 10px;
  }
`
const IconWrapper = styled.div`
  width: 40px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${theme.color.white[1]};
`
