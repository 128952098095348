import { useFullScreenHandle } from 'react-full-screen'
import { useState, useRef, useCallback, useEffect } from 'react'
import { Recording } from '../types'

const syncIntervalMs = 200

export const usePlayer = (hallRecording: Recording) => {
  const fullScreenHandle = useFullScreenHandle()

  const [currentTime, setCurrentTime] = useState(0)
  const [volume, setVolume] = useState(0.5)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const duration = hallRecording.duration

  const timeoutRef = useRef<number>()

  const tickCurrentTime = useCallback(() => {
    timeoutRef.current = window.setInterval(
      () =>
        setCurrentTime((prevCurrentTime) => {
          const rawCurrentTime = prevCurrentTime + syncIntervalMs / 1000
          const roundedCurrentTime =
            Math.floor(rawCurrentTime * 10 ** 2) / 10 ** 2
          return Math.min(duration, roundedCurrentTime)
        }),
      syncIntervalMs
    )
  }, [duration])

  const stopCurrentTime = useCallback(() => {
    timeoutRef.current && clearInterval(timeoutRef.current)
  }, [])

  const playVideo = useCallback(() => {
    tickCurrentTime()
    setIsPlaying(true)
  }, [tickCurrentTime])

  const pauseVideo = useCallback(() => {
    stopCurrentTime()
    setIsPlaying(false)
  }, [stopCurrentTime])

  const onSeek = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(e.target.value)
      stopCurrentTime()
      setCurrentTime(value)
      if (isPlaying) tickCurrentTime()
    },
    [isPlaying, tickCurrentTime, stopCurrentTime]
  )

  const onVolumeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = Number(e.target.value)
      setVolume(value)
      setIsMuted(value === 0)
    },
    []
  )

  const onVideoPlay = useCallback(() => {
    if (isPlaying) return
    if (duration <= currentTime) {
      setCurrentTime(0)
    }
    playVideo()
  }, [isPlaying, duration, currentTime, playVideo])

  const onMuteToggle = useCallback(() => {
    if (volume === 0) return
    setIsMuted((prev) => !prev)
  }, [volume])

  useEffect(() => {
    if (duration <= currentTime) {
      pauseVideo()
      return
    }
  }, [currentTime, pauseVideo, duration])

  return {
    fullScreenHandle,
    currentTime,
    duration,
    isPlaying,
    isMuted,
    volume,
    onSeek,
    onVolumeChange,
    onVideoPlay,
    onVideoPause: pauseVideo,
    onMuteToggle,
  }
}
