import React from 'react'
import styled from 'styled-components'
import { Txt as _Txt, NewTabLink } from '@blue-agency/rogue'
import reactStringReplace from 'react-string-replace'
import { href } from '@/constants/href'

const text = `
録画データを作成しています。
作成完了まで、しばらくお待ち下さい。
ヘルプページはこちら
`

const replacedText = reactStringReplace(text, /(こちら)/, (match, i) => (
  <NewTabLink href={href.supportPolicy} key={i} action="open_link_to_help_page">
    {match}
  </NewTabLink>
))

export const EncodingContent: React.FCX = ({ className }) => (
  <Wrapper className={className}>
    <Txt>{replacedText}</Txt>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Txt = styled(_Txt)`
  text-align: center;
`
