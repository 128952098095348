import { useRef, useCallback, useEffect, useState, useMemo } from 'react'
import Hls from 'hls.js'
import { Fragment } from '../types'

const maxGapMs = 1000

export type PlayerStatus = {
  volume: number
  isMuted: boolean
  isPlaying: boolean
  currentTime: number
}

type Props = {
  fragment?: Fragment
  playerStatus: PlayerStatus
  onHlsError: () => void
}

export const useVideo = ({ fragment, playerStatus, onHlsError }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const hls = useMemo(
    () => new Hls({ xhrSetup: (xhr) => (xhr.withCredentials = true) }),
    []
  )
  const elRef = useRef<HTMLVideoElement | null>(null)
  const playerStatusRef = useRef<PlayerStatus>(playerStatus)

  const videoRef = useCallback(
    (element: HTMLVideoElement | null) => {
      const handleErrorEvent = () => {
        setIsLoading(false)
        onHlsError()
      }
      const handleLoadstartEvent = () => {
        setIsLoading(true)
      }
      const handleCanplayEvent = () => {
        setIsLoading(false)
      }

      if (!element) {
        if (elRef.current) {
          elRef.current.removeEventListener('loadstart', handleLoadstartEvent)
          elRef.current.removeEventListener('canplay', handleCanplayEvent)
          elRef.current.removeEventListener('error', handleErrorEvent)
        }
        return
      }

      if (!fragment) return

      elRef.current = element

      if (Hls.isSupported()) {
        hls.attachMedia(element)

        hls.on(Hls.Events.MEDIA_ATTACHED, () => {
          hls.loadSource(fragment.videoUrl)
        })

        hls.on(Hls.Events.ERROR, (_, data) => {
          if (!data.fatal) return
          // FIXME: 再生終了時に'no EXTM3U delimiter'でエラーになることがある
          if ((data as any).reason === 'no EXTM3U delimiter') return
          setIsLoading(false)
          onHlsError()
          hls.destroy()
        })
      } else {
        element.src = fragment.videoUrl
      }

      element.addEventListener('loadstart', handleLoadstartEvent)
      element.addEventListener('canplay', handleCanplayEvent)
      element.addEventListener('error', handleErrorEvent)

      element.volume = playerStatusRef.current.volume
      element.muted = playerStatusRef.current.isMuted
      playerStatusRef.current.isPlaying && element.play().catch(() => {})
    },
    [fragment, onHlsError, hls]
  )

  useEffect(() => {
    playerStatusRef.current = playerStatus
  }, [playerStatus])

  useEffect(() => {
    return () => {
      hls.detachMedia()
    }
  }, [hls])

  useEffect(() => {
    if (!elRef.current || !fragment) return

    elRef.current.currentTime =
      playerStatus.currentTime - fragment.startTimeOffset

    if (playerStatus.isPlaying) {
      elRef.current.play().catch(() => {})
    } else {
      elRef.current.pause()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerStatus.isPlaying, fragment])

  useEffect(() => {
    const el = elRef.current
    if (!el || !fragment) return
    const gap = Math.abs(
      el.currentTime - (playerStatus.currentTime - fragment.startTimeOffset)
    )
    if (maxGapMs <= gap * 1000) {
      el.currentTime = playerStatus.currentTime - fragment.startTimeOffset
    }
  }, [playerStatus.currentTime, fragment])

  useEffect(() => {
    if (elRef.current) elRef.current.volume = playerStatus.volume
  }, [playerStatus.volume])

  useEffect(() => {
    if (elRef.current) elRef.current.muted = playerStatus.isMuted
  }, [playerStatus.isMuted])

  return [videoRef, isLoading] as const
}
