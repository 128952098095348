import React from 'react'
import styled from 'styled-components'
import { FullScreen, FullScreenHandle } from 'react-full-screen'
import {
  PlayerControls,
  PlayerControlsProps,
} from '@/components/PlayerControls'
import { SeekBar, SeekBarProps } from '@/components/SeekBar'
import { PlayOverlay as _PlayOverlay } from '@/components/PlayOverlay'

type Props = {
  fullScreenHandle: FullScreenHandle
  currentTime: number
  duration: number
  isPlaying: boolean
  isMuted: boolean
  volume: number
  onSeek: (e: React.ChangeEvent<HTMLInputElement>) => void
  onVolumeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onVideoPlay: () => void
  onVideoPause: () => void
  onMuteToggle: () => void
}

export const Player: React.FCX<Props> = (props) => {
  const seekBarProps: SeekBarProps = {
    duration: props.duration,
    currentTime: props.currentTime,
    onSeek: props.onSeek,
  }
  const controlsProps: PlayerControlsProps = {
    currentTime: props.currentTime,
    duration: props.duration,
    isPlaying: props.isPlaying,
    isMuted: props.isMuted,
    volume: props.volume,
    onVolumeChange: props.onVolumeChange,
    onVideoPlay: props.onVideoPlay,
    onVideoPause: props.onVideoPause,
    onMuteToggle: props.onMuteToggle,
    fullScreenHandle: props.fullScreenHandle,
  }

  return (
    <Wrapper className={props.className}>
      <FullScreen handle={props.fullScreenHandle}>
        <FullscreenContent>
          <ContentArea>
            {props.children}
            <PlayOverlay onPlay={props.onVideoPlay} active={!props.isPlaying} />
          </ContentArea>
          <Bottom>
            <SeekBar {...seekBarProps} />
            <PlayerControls {...controlsProps} />
          </Bottom>
        </FullscreenContent>
      </FullScreen>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .fullscreen {
    height: 100%;
    width: 100%;
  }
`
const FullscreenContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${Wrapper} & {
    height: 100%;
  }
`

const Bottom = styled.div`
  ${Wrapper} & {
    width: 100%;
  }
  position: fixed;
  bottom: 0;
  background: linear-gradient(180deg, #06060a 0%, #2e3253 100%);
  display: flex;
  flex-direction: column;
  flex: 0 0 35px;
`

const ContentArea = styled.div`
  position: relative;
  flex: 1 0;
`

const PlayOverlay = styled(_PlayOverlay)`
  ${ContentArea} & {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
`
