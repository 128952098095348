import React, { useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  ClickOutsideListener,
  Icon,
  theme,
  Txt,
  WeakColorSeparator,
} from '@blue-agency/rogue'

type Rate = number
export type PlaybackRateOption = {
  rate: Rate
  label: string
}
type Props = {
  rate: Rate
  options: PlaybackRateOption[]
  onChange: (rate: Rate) => void
}
export const PlaybackRate: React.FCX<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOptions = useCallback(() => {
    setIsOpen((prev) => !prev)
  }, [])
  const closeOptions = useCallback(() => {
    setIsOpen(false)
  }, [])
  const clickOutsideTargetRef = useRef(null)

  return (
    <div className={props.className}>
      <ClickOutsideListener
        onClick={closeOptions}
        targetRef={clickOutsideTargetRef}
      >
        <Wrapper ref={clickOutsideTargetRef}>
          <Control onClick={toggleOptions}>{props.rate}x</Control>
          {isOpen && (
            <Options length={props.options.length}>
              <OptionsTitle>再生速度</OptionsTitle>
              {props.options.map((option) => (
                <div key={option.rate}>
                  <WeakColorSeparator />
                  <Option
                    selectedRate={props.rate}
                    option={option}
                    onChange={props.onChange}
                    closeOptions={closeOptions}
                  />
                </div>
              ))}
            </Options>
          )}
        </Wrapper>
      </ClickOutsideListener>
    </div>
  )
}

type OptionProps = {
  selectedRate: Rate
  option: PlaybackRateOption
  onChange: (rate: Rate) => void
  closeOptions: () => void
}
const Option: React.FCX<OptionProps> = (props) => {
  const isSelected = useMemo(
    () => props.selectedRate === props.option.rate,
    [props]
  )
  const onClick = useCallback(() => {
    props.onChange(props.option.rate)
    props.closeOptions()
  }, [props])

  return (
    <OptionWrapper onClick={onClick}>
      {isSelected && <CheckIcon />}
      <OptionTxt isSelected={isSelected}>{props.option.label}</OptionTxt>
    </OptionWrapper>
  )
}

const optionHeight = 47
const separatorHeight = 1
const iconMarginLeft = 13.5
const rateTxtMarginLeft = 7.5

const Wrapper = styled.div`
  position: relative;
`

const Control = styled.button`
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  width: 30px;
  height: 16px;
  font-size: ${theme.fontSize.xs};
  border: 1px solid ${theme.color.white[1]};
  border-radius: 2px;
  color: ${theme.color.white[1]};
  background-color: transparent;
`

const Options = styled.ul<{ length: number }>`
  position: absolute;
  top: -${({ length }) => (length + 1) * (optionHeight + separatorHeight) + 10}px;
  right: 0;
  width: 200px;
  background-color: ${theme.color.white[1]};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`

const OptionsTitle = styled.div`
  height: ${optionHeight}px;
  display: flex;
  align-items: center;
  font-size: ${theme.fontSize.s};
  padding-left: 20px;
`

const OptionWrapper = styled.li`
  cursor: pointer;
  height: ${optionHeight}px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  &:hover {
    background-color: ${theme.color.gray[4]};
  }
`

const CheckIcon = styled(Icon).attrs({ name: 'check', size: 's' })`
  margin-left: ${iconMarginLeft}px;
  margin-bottom: 2px;
  color: ${theme.color.green[4]};
`

const OptionTxt = styled(Txt)<{ isSelected: boolean }>`
  margin-left: ${({ isSelected }) =>
    isSelected
      ? `${rateTxtMarginLeft}px`
      : `calc(${theme.iconSize.s} + ${iconMarginLeft + rateTxtMarginLeft}px)`};
`
