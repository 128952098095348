import { cacheKey } from '@/constants/cacheKey'
import {
  useCachedPromise,
  createContainer,
  CacheContainer,
} from '@blue-agency/react-utils'
import { useMemo, useEffect } from 'react'
import { GetInterviewRecordingResponse } from '@/pb/v2/im_video_player_bff/im_video_player_bff_service_pb'
import { useInterviewRecordingToken } from './useInterviewRecordingToken'
import { ImVideoPlayerBffContainer } from './useImVideoPlayerBff'
import {
  InterviewPinningObject,
  PinType,
} from '@/components/InterviewRecordingComponent/types'
import { InterviewPinning } from '@/pb/v2/im_video_player_bff/interview_pinning_data_pb'

export type InterviewRecordingVideoStatus =
  | 'not_finished'
  | 'encoding'
  | 'encoded'
  | 'failed'
  | 'deleted'

const useInterviewRecordingForEmbed = () => {
  const { token } = useInterviewRecordingToken()

  const { getInterviewRecording } = ImVideoPlayerBffContainer.useContainer()
  const { deleteCache } = CacheContainer.useContainer()

  const getInterviewRecordingRes = useCachedPromise(
    cacheKey.getInterviewRecording({ token }),
    async () => getInterviewRecording({ token })
  )

  const interviewRecording = useMemo(() => {
    const recording = getInterviewRecordingRes.getInterviewRecording()
    return recording ? recording.toObject() : null
  }, [getInterviewRecordingRes])

  const interviewPinnings = useMemo(() => {
    const pinnings: InterviewPinningObject[] = []
    getInterviewRecordingRes.getInterviewPinningsList().forEach((v) => {
      pinnings.push({
        staffName: v.getStaffName(),
        type: mapPinningType(v.getType()),
        pinnedTimeOffset: v.getPinnedTimeOffset(),
      })
    })
    return pinnings
  }, [getInterviewRecordingRes])

  useEffect(() => {
    return () => {
      deleteCache(cacheKey.getInterviewRecording({ token }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const status = useMemo(
    () => mapStatus(getInterviewRecordingRes.getStatus()),
    [getInterviewRecordingRes]
  )

  return {
    interviewRecording,
    status,
    interviewPinnings,
  }
}

export const InterviewRecordingForEmbedContainer = createContainer(
  useInterviewRecordingForEmbed
)

function mapStatus(
  resStatus: GetInterviewRecordingResponse.Status
): InterviewRecordingVideoStatus {
  switch (resStatus) {
    case GetInterviewRecordingResponse.Status.NOT_FINISHED:
      return 'not_finished'
    case GetInterviewRecordingResponse.Status.ENCODING:
      return 'encoding'
    case GetInterviewRecordingResponse.Status.ENCODED:
      return 'encoded'
    case GetInterviewRecordingResponse.Status.FAILED:
      return 'failed'
    case GetInterviewRecordingResponse.Status.DELETED:
      return 'deleted'
    default:
      throw new Error('unexpected status')
  }
}

function mapPinningType(
  resStatus: InterviewPinning.InterviewPinningType
): PinType {
  switch (resStatus) {
    case InterviewPinning.InterviewPinningType.INTERVIEW_PINNING_TYPE_GOOD:
      return 'good'
    case InterviewPinning.InterviewPinningType.INTERVIEW_PINNING_TYPE_BAD:
      return 'bad'
    case InterviewPinning.InterviewPinningType.INTERVIEW_PINNING_TYPE_QUESTION:
      return 'question'
    default:
      throw new Error('unexpected type')
  }
}
