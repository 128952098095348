import { Recording, Fragment } from './types'

export const getCurrentFragments = (
  recording: Recording,
  currentTime: number
) => {
  if (recording.studioVideosList.length < 2) {
    throw new Error('videos not found')
  }

  const currentScreenFragment = getCurrentFragment(
    currentTime,
    getScreenFragments(recording)
  )

  const currentPresenterFragment = getCurrentFragment(
    currentTime,
    getPresenterFragments(recording)
  )

  return {
    currentScreenFragment,
    currentPresenterFragment,
  }
}

const getScreenFragments = (recording: Recording): Fragment[] => {
  return recording.studioVideosList[0].fragmentsList
}

const getPresenterFragments = (recording: Recording): Fragment[] => {
  return recording.studioVideosList[1].fragmentsList
}

const getCurrentFragment = (
  currentTime: number,
  fragments: Fragment[]
): Fragment | undefined => {
  return (
    fragments.find((fragment) => {
      return (
        fragment.startTimeOffset <= currentTime &&
        currentTime <= fragment.stopTimeOffset
      )
    }) || undefined
  )
}
