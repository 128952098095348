import React from 'react'
import styled from 'styled-components'
import { Txt as _Txt } from '@blue-agency/rogue'

const text = `
対象のWeb面接が実施中のため、
録画データを再生できません。
`

export const NotInterviewFinishedContent: React.FCX = ({ className }) => (
  <Wrapper className={className}>
    <Txt>{text}</Txt>
  </Wrapper>
)

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Txt = styled(_Txt)`
  text-align: center;
`
