import React from 'react'
import styled from 'styled-components'
import { Icon } from '@blue-agency/rogue'

type Props = {
  active: boolean
  onPlay: () => void
}

export const PlayOverlay: React.FCX<Props> = ({
  className,
  onPlay,
  active,
}) => {
  if (!active) return null

  return (
    <Wrapper className={className}>
      <IconWrapper onClick={onPlay}>
        <PlayIcon name="play" />
      </IconWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(25, 25, 25, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
`

const IconWrapper = styled.div`
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 50%;
  position: relative;
  cursor: pointer;

  &:hover {
    border-color: white;
  }
`

const PlayIcon = styled(Icon)`
  color: #ccc;
  width: 30px;
  height: 38px;

  ${IconWrapper} & {
    position: absolute;
    left: 37%;
    top: 33%;
  }

  ${IconWrapper}:hover & {
    color: white;
  }
`
