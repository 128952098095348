import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { theme } from '@blue-agency/rogue'
import { useInterviewRecordingFragment } from '../hooks/useInterviewRecordingFragment'
import { ShownFragment } from '../types'
import { InterviewRecordingPlayerContainer } from '../hooks/useInterviewRecordingPlayer'
import { InterviewUserType } from '../UsersArea/RecordingVideos'
import { Spinner } from '@/components/Spinner'

type Props = {
  width: number
  userType: InterviewUserType
  shownFragment: ShownFragment
  index: number
}

export const RecordingVideo: React.FCX<Props> = ({ className, ...props }) => {
  const { handleMainFragmentSelect } =
    InterviewRecordingPlayerContainer.useContainer()
  const { videoRef, isLoading, isMuted } = useInterviewRecordingFragment(
    props.shownFragment.fragment,
    false
  )
  const width = props.width / 2
  const height = (width * 5) / 8

  const handleClick = useCallback(() => {
    handleMainFragmentSelect(props.shownFragment)
  }, [handleMainFragmentSelect, props.shownFragment])

  return (
    <Wrapper
      className={className}
      onClick={handleClick}
      width={width}
      height={height}
    >
      {isLoading && (
        <SpinnerOverlay>
          <Spinner color="white" />
        </SpinnerOverlay>
      )}
      <Video
        muted={isMuted}
        playsInline
        width={props.width}
        isMain={props.shownFragment.isMain}
        userType={props.userType}
        ref={videoRef}
      />
    </Wrapper>
  )
}

const buttonResetStyle = css`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
`
const Wrapper = styled.button<{ width: number; height: number }>`
  position: relative;

  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
  `};
  ${buttonResetStyle};
`

const SpinnerOverlay = styled.div`
  ${Wrapper} & {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`

type VideoProps = {
  isMain: boolean
  userType: InterviewUserType
}
const Video = styled.video<VideoProps>`
  ${Wrapper} & {
    width: 100%;
    height: 100%;
  }
  background: ${theme.color.black[1]};
  border: 2px solid;
  ${({ userType, isMain }) => {
    switch (userType) {
      case 'interviewer':
        return css`
          border-color: ${isMain ? theme.color.green[4] : theme.color.white[1]};
        `
      case 'interviewee':
        return css`
          border-color: ${isMain ? theme.color.red.iM : theme.color.white[1]};
        `
    }
  }}
`
