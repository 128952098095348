const makeCacheKey =
  <T extends { [key: string]: string } | void>(key: string) =>
  (params: T) => {
    return (
      key +
      '-' +
      Object.values((params as any) || {})
        .sort()
        .join('-')
    )
  }
export const cacheKey = {
  getInterviewRecording: makeCacheKey<{ token: string }>(
    'getInterviewRecording'
  ),
  getHallRecording: makeCacheKey<{ token: string }>('getHallRecording'),
}
