import React from 'react'
import { useHallRecordingToken } from '@/hooks/useHallRecordingToken'
import { useTitle } from '@/hooks/useTitle'
import { ExpiredPage } from './ExpiredPage'
import { PlayerPage } from './PlayerPage'
import { provide } from '@blue-agency/react-utils'
import { ImVideoPlayerBffContainer } from '@/hooks/useImVideoPlayerBff'

export const EmbeddedHallRecordingPlayerPage: React.FCX = provide(
  ImVideoPlayerBffContainer
)(() => {
  const { isExpired } = useHallRecordingToken()

  useTitle('Web説明会 録画データ')

  return isExpired ? <ExpiredPage /> : <PlayerPage />
})
