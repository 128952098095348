import React from 'react'
import styled, { css } from 'styled-components'
import { theme, Icon, Txt } from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/im'
import { InterviewRecordingPlayerContainer } from './hooks/useInterviewRecordingPlayer'
import { PinType } from './types'

type PinStyleValues = {
  [k in PinType]: {
    color: string
    tooltipColor: string
    tooltipBgColor: string
  }
}

const pinStyleValues: PinStyleValues = {
  good: {
    color: theme.color.blue[1],
    tooltipColor: theme.color.blue[1],
    tooltipBgColor: theme.color.blue[4],
  },
  bad: {
    color: theme.color.red[1],
    tooltipColor: theme.color.red[2],
    tooltipBgColor: theme.color.red[4],
  },
  question: {
    color: theme.color.orange[1],
    tooltipColor: theme.color.orange[1],
    tooltipBgColor: theme.color.orange[2],
  },
}

export const SeekBar: React.FCX = ({ className }) => {
  const { duration, currentTime, handleSeek, interviewPinnings } =
    InterviewRecordingPlayerContainer.useContainer()
  const toolTipTopRight = (type: PinType) => {
    switch (type) {
      case 'good':
        return (
          <TooltipTopRight>
            <InterviewPinningGoodFaceIcon />
            <TooltipTxt type={type}>グッド</TooltipTxt>
          </TooltipTopRight>
        )
      case 'bad':
        return (
          <TooltipTopRight>
            <InterviewPinningBadFaceIcon />
            <TooltipTxt type={type}>イマイチ</TooltipTxt>
          </TooltipTopRight>
        )
      case 'question':
        return (
          <TooltipTopRight>
            <InterviewPinningQuestionFaceIcon />
            <TooltipTxt type={type}>要確認</TooltipTxt>
          </TooltipTopRight>
        )
    }
  }
  return (
    <>
      <Input
        className={className}
        type="range"
        min={0}
        max={duration}
        step={1}
        value={currentTime}
        onChange={handleSeek}
      />
      {interviewPinnings?.map((pin, i) => {
        const pinPosition =
          100 * (Number(pin.pinnedTimeOffset) / Number(duration))
        return (
          <React.Fragment key={i}>
            <PinTooltip
              id={i.toString()}
              arrowPosition={
                pinPosition < 10
                  ? 'leftBottom'
                  : pinPosition > 90
                  ? 'rightBottom'
                  : 'bottomLeft'
              }
              type={pin.type}
              position={pinPosition}
            >
              <TooltipTop>
                <PinTime>{toHms(pin.pinnedTimeOffset)}</PinTime>
                {toolTipTopRight(pin.type)}
              </TooltipTop>
              <TooltipBottom>
                <PinnedStaffName>{pin.staffName}</PinnedStaffName>
              </TooltipBottom>
            </PinTooltip>
            <Pin
              type={pin.type}
              position={pinPosition}
              data-tip
              data-for={i.toString()}
            />
          </React.Fragment>
        )
      })}
    </>
  )
}
const toHms = (seconds: number) => {
  const sec = Math.floor(seconds) % 60
  const min = Math.floor(seconds / 60) % 60
  const hour = Math.floor(seconds / 3600)
  return [
    ('00' + hour).slice(-2),
    ('00' + min).slice(-2),
    ('00' + sec).slice(-2),
  ].join(':')
}

const Input = styled.input`
  -webkit-appearance: none;
  margin: 0;
  width: 100%;
  height: 6px;
  cursor: pointer;
  outline: none;
  ${({ value, max }) => {
    const percent = Math.floor(100 * (Number(value) / Number(max)))
    return css`
      background: linear-gradient(
        to right,
        ${theme.color.green[4]} 0%,
        ${theme.color.green[4]} ${percent}%,
        ${theme.color.gray[3]} ${percent}%,
        ${theme.color.gray[3]} 100%
      );
    `
  }}
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* MEMO: width, height: 0を設定しないとSeekBar上をクリックしたときに反応しない */
    width: 0;
    height: 0;
  }
`

const Pin = styled.div<{ type: PinType; position: number }>`
  width: 12px;
  height: 12px;
  background-color: ${({ type }) => pinStyleValues[type].color};
  border: solid 1.5px ${theme.color.white[1]};
  border-radius: 50%;
  left: ${({ position }) => position}%;
  position: absolute;
  margin-top: -2px;
`

const PinTooltip = styled(Tooltip)<{ type: PinType; position: number }>`
  && {
    background: ${({ type }) => pinStyleValues[type].tooltipBgColor};
    padding: 8px;
    border: 1px solid ${({ type }) => pinStyleValues[type].tooltipColor};
  }
  &&.place-top::after {
    background: ${({ type }) => pinStyleValues[type].tooltipBgColor};
    border: 1px solid ${({ type }) => pinStyleValues[type].tooltipColor};
    border-left-color: transparent;
    border-top-color: transparent;
  }
  &&.place-right::after {
    background: ${({ type }) => pinStyleValues[type].tooltipBgColor};
    border: 1px solid ${({ type }) => pinStyleValues[type].tooltipColor};
    border-top-color: transparent;
    border-right-color: transparent;
    top: 50%;
  }
  &&.place-left::after {
    background: ${({ type }) => pinStyleValues[type].tooltipBgColor};
    border: 1px solid ${({ type }) => pinStyleValues[type].tooltipColor};
    border-bottom-color: transparent;
    border-left-color: transparent;
    top: 50%;
  }
`
const PinTime = styled(Txt).attrs({ size: 's' })`
  color: ${theme.color.navy[1]};
`

const TooltipTop = styled.div`
  display: flex;
  justify-content: space-between;
`
const TooltipBottom = styled.div`
  display: flex;
`

const TooltipTopRight = styled.div`
  display: flex;
`
const TooltipTxt = styled(Txt).attrs({ size: 's' })<{ type: PinType }>`
  color: ${({ type }) => pinStyleValues[type].tooltipColor};
`

const PinnedStaffName = styled(Txt)`
  color: ${theme.color.gray[1]};
  font-size: 11px;
  margin-left: auto;
`

const InterviewPinningGoodFaceIcon = styled(Icon).attrs({ name: 'good-face' })`
  color: ${theme.color.blue[1]};
  margin-right: 2px;
  margin-left: 8px;
`
const InterviewPinningBadFaceIcon = styled(Icon).attrs({ name: 'bad-face' })`
  color: ${theme.color.red[2]};
  margin-right: 2px;
  margin-left: 8px;
`
const InterviewPinningQuestionFaceIcon = styled(Icon).attrs({
  name: 'question-face',
})`
  color: ${theme.color.orange[1]};
  margin-right: 2px;
  margin-left: 8px;
`
