import React, { useCallback } from 'react'
import styled from 'styled-components'
import {
  Modal,
  Txt,
  PrimaryButton,
  WeakColorSeparator,
  NewTabLink,
} from '@blue-agency/rogue'
import reactStringReplace from 'react-string-replace'
import { href } from '@/constants/href'

type Props = {
  active: boolean
}

const textContent = `録画データを再生することができませんでした。
再読込して、再度お試しください。
再読込で復旧しない場合は、サポートデスクまでお問い合わせください。
`

const replacedTextContent = reactStringReplace(
  textContent,
  /(サポートデスク)/,
  (match, i) => (
    <NewTabLink key={i} href={href.supportPolicy} action="open_link_to_sd">
      {match}
    </NewTabLink>
  )
)

export const RecordingErrorModal: React.FCX<Props> = ({
  className,
  active,
}) => {
  const handleReloadClick = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <Modal
      className={className}
      active={active}
      title="再生エラー"
      overlayZIndex={2}
      size="m"
    >
      <Upper>
        <Txt>{replacedTextContent}</Txt>
      </Upper>
      <WeakColorSeparator />
      <Bottom>
        <ButtonGroup>
          <PrimaryButton
            text="再読込"
            onClick={handleReloadClick}
            comlinkPushParams={{
              action: 'reload_on_recording_error_modal',
            }}
            size="l2"
          />
        </ButtonGroup>
      </Bottom>
    </Modal>
  )
}

const Upper = styled.div`
  padding: 20px 20px 32px;
`
const Bottom = styled.div`
  padding: 20px 16px;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
