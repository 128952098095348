import React from 'react'
import styled from 'styled-components'
import { theme } from '@blue-agency/rogue'
import { RecordingVideo } from '../UsersArea/RecordingVideo'
import { ShownFragment } from '../types'
import { usersAreaWidth } from '../InterviewRecordingLayout'

type Props = {
  userType: InterviewUserType
  shownFragments: ShownFragment[]
}

export type InterviewUserType = 'interviewer' | 'interviewee'

export const RecordingVideos: React.FCX<Props> = ({
  className,
  userType,
  shownFragments,
}) => {
  return (
    <Wrapper className={className}>
      <VideoGroup isInterviewer={userType === 'interviewer'}>
        {shownFragments.map((shownFragment, index) => (
          <RecordingVideo
            key={shownFragment.id}
            index={index}
            userType={userType}
            shownFragment={shownFragment}
            width={usersAreaWidth}
          />
        ))}
      </VideoGroup>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: ${usersAreaWidth}px;
`
const VideoGroup = styled.div<{
  isInterviewer?: boolean
}>`
  ${Wrapper} & {
    width: 100%;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 4px solid;
  border-color: ${({ isInterviewer }) =>
    isInterviewer ? theme.color.green[4] : theme.color.red.iM};
`
