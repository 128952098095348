import { useMemo, useEffect } from 'react'
import { CacheContainer, useCachedPromise } from '@blue-agency/react-utils'
import { useInterviewRecordingToken } from '@/hooks/useInterviewRecordingToken'
import { ImVideoPlayerBffContainer } from '@/hooks/useImVideoPlayerBff'
import { cacheKey } from '@/constants/cacheKey'

export const useHallRecording = () => {
  const { token } = useInterviewRecordingToken()

  const { getHallRecording } = ImVideoPlayerBffContainer.useContainer()
  const { deleteCache } = CacheContainer.useContainer()

  const getHallRecordingRes = useCachedPromise(
    cacheKey.getHallRecording({ token }),
    () => getHallRecording({ token })
  )

  const hallRecording = useMemo(() => {
    const recording = getHallRecordingRes.getStudioRecording()
    if (!recording) throw new Error('recording not found')
    return recording.toObject()
  }, [getHallRecordingRes])

  useEffect(() => {
    return () => deleteCache(cacheKey.getHallRecording({ token }))
  }, [token, deleteCache])

  useEffect(() => {
    hallRecording.cookiesList.forEach((cookie) => {
      document.cookie = `${cookie.key}=${cookie.value};domain=${cookie.domain};path=/;secure;samesite=none;`
    })
  }, [hallRecording.cookiesList])

  return { hallRecording }
}
