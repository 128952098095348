import { useRef, useCallback, useEffect, useState } from 'react'
import Hls from 'hls.js'
import { InterviewRecordingFragment } from '../types'
import { InterviewRecordingPlayerContainer } from './useInterviewRecordingPlayer'
import { Comlink } from '@blue-agency/comlink'

export function useInterviewRecordingFragment(
  props: InterviewRecordingFragment,
  isMain: boolean
) {
  const {
    interviewGuid,
    volume,
    isMuted,
    isPlaying,
    currentTime,
    handleHlsError,
    playbackRate,
    seekTrigger,
  } = InterviewRecordingPlayerContainer.useContainer()

  const [isLoading, setIsLoading] = useState(false)

  const hlsRef = useRef(
    new Hls({ xhrSetup: (xhr) => (xhr.withCredentials = true) })
  )
  const elRef = useRef<HTMLVideoElement | null>(null)
  const startLoadingTimeRef = useRef(0)
  const [loadingTime, setLoadingTime] = useState<number | undefined>(undefined)

  const videoRef = useCallback(
    (element: HTMLVideoElement | null) => {
      if (!element) return

      if (isMain) {
        element.muted = true
      }

      elRef.current = element

      const hls = hlsRef.current

      if (Hls.isSupported()) {
        hls.attachMedia(element)

        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          Comlink.push({
            type: 'system_activity',
            group: 'video_player',
            action: 'load_interview_recording_hls_src_when_supported',
            targetName: 'interviewGuid',
            targetIdStr: interviewGuid,
          })
          hls.loadSource(props.videoUrl)
        })
        ;(hls as Hls).on(Hls.Events.ERROR, (_, data) => {
          if (
            // NOTE: fatalなエラー以外は無視する
            !data.fatal ||
            // NOTE: 長さ0のtsファイルが存在した時に再生エラーになってしまうが再生は続けたいので握りつぶしている
            (data as any).reason === 'invalid target duration'
          ) {
            Comlink.push({
              type: 'system_activity',
              group: 'video_player',
              action: `Ignore hls error`,
              targetName: 'interviewGuid',
              targetIdStr: interviewGuid,
              metadata: {
                message: `${data.details}, ${(data as any).reason}`,
              },
            })
            return
          }

          Comlink.push({
            type: 'system_activity',
            group: 'video_player',
            action: `Hls.js load error`,
            targetName: 'interviewGuid',
            targetIdStr: interviewGuid,
            metadata: {
              message: `${data.details}, ${(data as any).reason}`,
            },
          })

          setIsLoading(false)
          handleHlsError()
          hls.destroy()
        })
      } else {
        Comlink.push({
          type: 'system_activity',
          group: 'video_player',
          action: 'load_interview_recording_hls_src_when_not_supported',
          targetName: 'interviewGuid',
          targetIdStr: interviewGuid,
        })
        element.addEventListener('error', (event) => {
          Comlink.push({
            type: 'system_activity',
            group: 'video_player',
            action: 'Hls load error',
            targetName: 'interviewGuid',
            targetIdStr: interviewGuid,
            metadata: {
              message: event.message,
            },
          })
          handleHlsError()
        })
        element.src = props.videoUrl
      }

      element.addEventListener('loadstart', () => {
        setIsLoading(true)
        startLoadingTimeRef.current = performance.now()
      })
      element.addEventListener('loadeddata', () => {
        // NOTE: playbackRateはロード後に設定する必要がある
        element.playbackRate = playbackRate
        setIsLoading(false)
        const loadingTime =
          (performance.now() - startLoadingTimeRef.current) / 1000
        setLoadingTime(loadingTime)
      })
      element.addEventListener('error', () => {
        setIsLoading(false)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.videoUrl]
  )

  useEffect(() => {
    if (elRef.current) {
      elRef.current.playbackRate = playbackRate
    }
  }, [playbackRate])

  useEffect(() => {
    const hls = hlsRef.current

    return () => {
      hls.detachMedia()
    }
  }, [])

  // 再生/停止された時にcurrentTimeを更新して再生/停止を行う
  useEffect(() => {
    if (!elRef.current) return
    elRef.current.currentTime = currentTime - props.startTimeOffset

    if (isPlaying) {
      elRef.current.play().catch(() => {})
    } else {
      elRef.current.pause()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying])

  // 初回読み込み時のローディング時間分だけcurrentTimeを調整
  // ↑が主な目的だが、メイン画面を切り替えた時に、メイン画面のcurrentTimeを更新して再生/停止を行う処理もここで担っている
  useEffect(() => {
    if (!loadingTime) return
    if (!elRef.current) return

    elRef.current.currentTime =
      currentTime - props.startTimeOffset + loadingTime

    if (isPlaying) {
      elRef.current.play().catch(() => {})
    } else {
      elRef.current.pause()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingTime])

  // シークされた時にcurrentTimeを更新する
  useEffect(() => {
    if (!elRef.current) return
    elRef.current.currentTime = currentTime - props.startTimeOffset
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seekTrigger])

  useEffect(() => {
    if (elRef.current) elRef.current.volume = volume
  }, [volume])

  useEffect(() => {
    if (isMain) return
    if (elRef.current) elRef.current.muted = isMuted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMuted])

  return { videoRef, isLoading, isMuted }
}
