import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { FullScreenHandle } from 'react-full-screen'
import { Icon, Txt, theme } from '@blue-agency/rogue'
import { VolumeRange } from '@/components/VolumeRange'
import { Comlink } from '@blue-agency/comlink'

export type PlayerControlsProps = {
  fullScreenHandle: FullScreenHandle
  currentTime: number
  duration: number
  isPlaying: boolean
  isMuted: boolean
  volume: number
  onVolumeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onVideoPlay: () => void
  onVideoPause: () => void
  onMuteToggle: () => void
}

export const PlayerControls: React.FCX<PlayerControlsProps> = ({
  className,
  currentTime,
  duration,
  isPlaying,
  isMuted,
  volume,
  onVolumeChange,
  onVideoPlay,
  onVideoPause,
  onMuteToggle,
  fullScreenHandle,
}) => {
  const timeLabel = useMemo(
    () => toHms(currentTime) + ' / ' + toHms(duration),
    [currentTime, duration]
  )

  const handleFullscreenRequest = useCallback(() => {
    try {
      fullScreenHandle.enter()
    } catch (e) {
      Comlink.push({
        type: 'system_activity',
        group: 'video_player',
        action: 'failed_to_enter_fullscreen_in_recording',
        metadata: {
          errorMessage: e.message ?? '',
        },
      })
      alert('全画面表示に対応していません。')
    }
  }, [fullScreenHandle])

  const handleFullscreenExitRequest = useCallback(() => {
    try {
      fullScreenHandle.exit()
    } catch (e) {
      Comlink.push({
        type: 'system_activity',
        group: 'video_player',
        action: 'failed_to_exit_fullscreen_in_recording',
        metadata: {
          errorMessage: e.message ?? '',
        },
      })
      alert('全画面表示の解除に失敗しました。')
    }
  }, [fullScreenHandle])

  return (
    <Wrapper className={className}>
      <Left>
        <IconWrapper onClick={isPlaying ? onVideoPause : onVideoPlay}>
          <Icon name={isPlaying ? 'pause' : 'play'} />
        </IconWrapper>
        <TimeLabelTxt size="xs" color={theme.color.white[1]}>
          {timeLabel}
        </TimeLabelTxt>
      </Left>
      <Right>
        <VolumeRange
          volume={volume}
          isMuted={isMuted}
          onVolumeChange={onVolumeChange}
        />
        <IconWrapper onClick={onMuteToggle}>
          <Icon name={isMuted ? 'mute' : 'volume'} />
        </IconWrapper>
        <IconWrapper
          onClick={
            fullScreenHandle.active
              ? handleFullscreenExitRequest
              : handleFullscreenRequest
          }
        >
          <Icon
            name={fullScreenHandle.active ? 'exit-fullscreen' : 'fullscreen'}
          />
        </IconWrapper>
      </Right>
    </Wrapper>
  )
}

const toHms = (seconds: number) => {
  const sec = Math.floor(seconds) % 60
  const min = Math.floor(seconds / 60) % 60
  const hour = Math.floor(seconds / 3600)
  return [
    ('00' + hour).slice(-2),
    ('00' + min).slice(-2),
    ('00' + sec).slice(-2),
  ].join(':')
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
const Left = styled.div`
  display: flex;
  align-items: center;
`
const Right = styled.div`
  ${Wrapper} & {
    margin-left: auto;
  }
  display: flex;
  align-items: center;
`
const TimeLabelTxt = styled(Txt)`
  ${Left} & {
    margin-left: 10px;
  }
`
const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${theme.color.white[1]};
`
