import React, { ReactNode, useMemo, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { theme } from '@blue-agency/rogue'

type Props = {
  presenterVideo: ReactNode
  screenShareVideo: ReactNode
  screenSharing: boolean
}

export const HallRecordingLayout: React.FCX<Props> = ({
  presenterVideo,
  screenShareVideo,
  screenSharing,
  className,
}) => {
  const [wrapperWidth, setWrapperWidth] = useState(0)
  const [wrapperHeight, setWrapperHeight] = useState(0)
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const el = wrapperRef.current
    if (!el) return

    const setWindowSize = () => {
      setWrapperWidth(el.clientWidth)
      setWrapperHeight(el.clientHeight)
    }

    setWindowSize()
    window.addEventListener('resize', setWindowSize)

    return () => window.removeEventListener('resize', setWindowSize)
  }, [])

  const mainVideoHeight = useMemo(
    () =>
      screenSharing
        ? wrapperWidth * 0.68 * (9 / 16)
        : Math.min(wrapperWidth * 1.0 * (9 / 16), wrapperHeight),
    [wrapperWidth, wrapperHeight, screenSharing]
  )
  const subVideoHeight = useMemo(
    () => wrapperWidth * 0.28 * (9 / 16),
    [wrapperWidth]
  )

  return (
    <Wrapper className={className} ref={wrapperRef}>
      <Left screenSharing={screenSharing}>
        <MainVideoArea height={mainVideoHeight}>
          {screenSharing ? screenShareVideo : presenterVideo}
        </MainVideoArea>
      </Left>
      {screenSharing && (
        <Right>
          <SubVideoArea height={subVideoHeight}>{presenterVideo}</SubVideoArea>
        </Right>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  background: ${theme.color.gray[3]};
`
const Left = styled.div<{ screenSharing: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 ${({ screenSharing }) => (screenSharing ? '68%' : '100%')};
`
const Right = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 28%;
`
const MainVideoArea = styled.div<{ height: number }>`
  background: ${theme.color.black[1]};
  width: 100%;
  height: ${({ height }) => height}px;
`
const SubVideoArea = styled.div<{ height: number }>`
  background: ${theme.color.black[1]};
  width: 100%;
  height: ${({ height }) => height}px;
`
