import { useMemo } from 'react'
import jwtDecode from 'jwt-decode'
import { useParams } from 'react-router-dom'

type InterviewRecordingTokenDecoded = {
  exp?: number
  nbf?: number
  sub?: string
}

export function useInterviewRecordingToken() {
  const { token } = useParams<{ token: string }>()

  if (!token) throw new Error('token not found')

  const decodedToken = useMemo(() => {
    let decoded: InterviewRecordingTokenDecoded

    try {
      decoded = jwtDecode<InterviewRecordingTokenDecoded>(token)
    } catch {
      throw new Error('failed to decode interviewRecordingToken')
    }

    if (!decoded.exp) {
      throw new Error('interviewRecordingToken is invalid')
    }

    return decoded
  }, [token])

  const interviewGuid = useMemo(() => {
    if (decodedToken.sub === undefined) {
      throw new Error("interviewRecording token don't have sub")
    }
    return decodedToken.sub
  }, [decodedToken])

  const expiresAt = useMemo(() => {
    if (decodedToken.exp === undefined) {
      throw new Error("interviewRecording token don't have exp")
    }
    return new Date(decodedToken.exp * 1000)
  }, [decodedToken])

  const isExpired = useMemo(() => {
    const now = new Date()

    return now > expiresAt
  }, [expiresAt])

  return {
    token,
    interviewGuid,
    isExpired,
  }
}
