import { useEffect } from 'react'

export type Options = {
  addSuffix?: boolean
}

const defaultOptions: Options = {
  addSuffix: true,
}

const defaultTitle = 'インタビューメーカー'

export const useTitle = (title: string, options: Options = defaultOptions) => {
  const appliedTitle = options.addSuffix ? `${title}｜${defaultTitle}` : title

  useEffect(() => {
    document.title = appliedTitle

    return () => {
      document.title = defaultTitle
    }
  }, [appliedTitle])
}
