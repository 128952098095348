import { useMemo } from 'react'
import jwtDecode from 'jwt-decode'
import { useParams } from 'react-router-dom'

type HallRecordingTokenDecoded = {
  exp?: number
  nbf?: number
  sub?: string
}

export function useHallRecordingToken() {
  const { token } = useParams<{ token: string }>()

  if (!token) throw new Error('token not found')

  const decodedToken = useMemo(() => {
    let decoded: HallRecordingTokenDecoded

    try {
      decoded = jwtDecode<HallRecordingTokenDecoded>(token)
    } catch {
      throw new Error('failed to decode hallRecordingToken')
    }

    if (!decoded.exp) {
      throw new Error('hallRecordingToken is invalid')
    }

    return decoded
  }, [token])

  const hallGuid = useMemo(() => {
    if (decodedToken.sub === undefined) {
      throw new Error("hallRecording token don't have sub")
    }
    return decodedToken.sub
  }, [decodedToken])

  const expiresAt = useMemo(() => {
    if (decodedToken.exp === undefined) {
      throw new Error("hallRecording token don't have exp")
    }
    return new Date(decodedToken.exp * 1000)
  }, [decodedToken])

  const isExpired = useMemo(() => {
    const now = new Date()

    return now > expiresAt
  }, [expiresAt])

  return {
    token,
    hallGuid,
    isExpired,
  }
}
