import React from 'react'
import styled, { css } from 'styled-components'
import { theme } from '@blue-agency/rogue'

export type SeekBarProps = {
  duration: number
  currentTime: number
  onSeek: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const SeekBar: React.FCX<SeekBarProps> = ({
  className,
  duration,
  currentTime,
  onSeek,
}) => (
  <Input
    className={className}
    type="range"
    min={0}
    max={duration}
    step={1}
    value={currentTime}
    onChange={onSeek}
  />
)

const Input = styled.input`
  -webkit-appearance: none;
  margin: 0;
  width: 100%;
  height: 6px;
  cursor: pointer;
  outline: none;
  ${({ value, max }) => {
    const percent = Math.floor(100 * (Number(value) / Number(max)))
    return css`
      background: linear-gradient(
        to right,
        ${theme.color.green[4]} 0%,
        ${theme.color.green[4]} ${percent}%,
        ${theme.color.gray[3]} ${percent}%,
        ${theme.color.gray[3]} 100%
      );
    `
  }}
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* MEMO: width, height: 0を設定しないとSeekBar上をクリックしたときに反応しない */
    width: 0;
    height: 0;
  }
`
