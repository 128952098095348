import React from 'react'
import styled from 'styled-components'
import { theme } from '@blue-agency/rogue'
import { InterviewRecordingPlayerContainer } from './hooks/useInterviewRecordingPlayer'
import { useInterviewRecordingFragment } from './hooks/useInterviewRecordingFragment'
import { InterviewRecordingFragment } from './types'
import { Spinner } from '@/components/Spinner'

export const VideoArea: React.FCX = ({ className }) => {
  const { mainFragment } = InterviewRecordingPlayerContainer.useContainer()
  if (!mainFragment) return null
  return (
    <Wrapper className={className}>
      <Inner fragment={mainFragment} />
    </Wrapper>
  )
}

const Inner: React.FCX<{ fragment: InterviewRecordingFragment }> = ({
  className,
  fragment,
}) => {
  const { videoRef, isLoading } = useInterviewRecordingFragment(fragment, true)
  return (
    <Wrapper className={className}>
      {isLoading && (
        <SpinnerOverlay>
          <Spinner size={88} color="white" />
        </SpinnerOverlay>
      )}
      {/* NOTE: 音声が二重になるのでメインビデオは常にミュート */}
      <MainVideo muted playsInline ref={videoRef} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: auto 0;
`
const MainVideo = styled.video`
  background: ${theme.color.black[1]};
  ${Wrapper} & {
    width: 100%;
    height: 100%;
  }
`

const SpinnerOverlay = styled.div`
  ${Wrapper} & {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`
