import React from 'react'
import styled, { css } from 'styled-components'
import { theme, Icon, Txt, NewTabLink } from '@blue-agency/rogue'
import { WindowSize } from '@blue-agency/react-utils/dist/useWindowSize'

type Props = {
  href: string
  windowSize: WindowSize
}

export const UnsupportedBrowser: React.FCX<Props> = ({ href, windowSize }) => {
  return (
    <Wrapper windowSize={windowSize}>
      <Center>
        <Logo name="logo" />
        <Message size="l">
          ご利用のウェブブラウザは推奨環境ではありません。
          <br />
          Google Chromeなど、別のブラウザでご利用ください。
          <br />
          推奨環境は
          <NewTabLink
            href={href}
            hideIcon
            action="open_link_to_supported_environment"
          >
            こちら
          </NewTabLink>
        </Message>
      </Center>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ windowSize: { width: number; height: number } }>`
  ${({ windowSize }) => css`
    width: ${windowSize.width}px;
    height: ${windowSize.height}px;
  `};
  background: ${theme.color.white[1]};
`
const Center = styled.div`
  ${Wrapper} & {
    width: 100%;
    margin: 0 auto;
  }
  text-align: center;
`
const Logo = styled(Icon)`
  width: 200px;
  height: 65px;
  ${Wrapper} & {
    margin-top: 100px;
  }
`
const Message = styled(Txt)`
  /* MEMO: importantを使わないようにするにはrogueのTxtを修正する必要がありそう */
  line-height: 1.8 !important;
  ${Wrapper} & {
    margin-top: 34px;
  }
`
