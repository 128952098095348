import React, { useMemo, useState, useCallback } from 'react'
import styled from 'styled-components'
import { usePlayer } from './hooks/usePlayer'
import { useHallRecording } from './hooks/useHallRecording'
import { Player } from '@/components/Player'
import { RecordingVideo } from '@/components/RecordingVideo'
import { HallRecordingLayout } from '@/components/HallRecordingLayout'
import { RecordingErrorModal } from '@/components/RecordingErrorModal'
import { getCurrentFragments } from './getCurrentFragments'
import { useVideo, PlayerStatus } from './hooks/useVideo'

export const PlayerPage: React.FCX = ({ className }) => {
  const { hallRecording } = useHallRecording()
  const playerProps = usePlayer(hallRecording)

  const [isError, setIsError] = useState(false)

  const handleHlsError = useCallback(() => {
    setIsError(true)
  }, [])

  const { currentScreenFragment, currentPresenterFragment } =
    getCurrentFragments(hallRecording, playerProps.currentTime)

  const screenSharing = useMemo(
    () => !!currentScreenFragment,
    [currentScreenFragment]
  )

  const playerStatus: PlayerStatus = {
    volume: playerProps.volume,
    isMuted: playerProps.isMuted,
    isPlaying: playerProps.isPlaying,
    currentTime: playerProps.currentTime,
  }

  const [screenVideoRef, isScreenLoading] = useVideo({
    fragment: currentScreenFragment,
    playerStatus,
    onHlsError: handleHlsError,
  })
  const [presenterVideoRef, isPresenterLoading] = useVideo({
    fragment: currentPresenterFragment,
    playerStatus,
    onHlsError: handleHlsError,
  })

  return (
    <Wrapper className={className}>
      <Player {...playerProps}>
        <HallRecordingLayout
          presenterVideo={
            <RecordingVideo
              ref={presenterVideoRef}
              isLoading={isPresenterLoading}
            />
          }
          screenShareVideo={
            <RecordingVideo ref={screenVideoRef} isLoading={isScreenLoading} />
          }
          screenSharing={screenSharing}
        />
      </Player>
      <RecordingErrorModal active={isError} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
