import { useMemo } from 'react'
import { createContainer } from '@blue-agency/react-utils'

const useBrowser = () => {
  const isInternetExplorer = useMemo(() => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return (
      userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1
    )
  }, [])

  return { isInternetExplorer }
}

export const BrowserContainer = createContainer(useBrowser)
