import React from 'react'
import styled from 'styled-components'
import { Txt as _Txt, NewTabLink } from '@blue-agency/rogue'
import reactStringReplace from 'react-string-replace'
import { href } from '@/constants/href'

const text = `
録画データ作成に失敗しました。
ヘルプページをご確認ください。
`

const replacedText = reactStringReplace(text, /(ヘルプページ)/, (match, i) => (
  <NewTabLink href={href.supportPolicy} key={i} action="open_link_to_help_page">
    {match}
  </NewTabLink>
))

export const FailedContent: React.FCX = ({ className }) => (
  <Wrapper className={className}>
    <Txt>{replacedText}</Txt>
  </Wrapper>
)

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Txt = styled(_Txt)`
  text-align: center;
`
