import { useEffect } from 'react'
import { Comlink } from '@blue-agency/comlink'
import { initTracking as initRogueTracking } from '@blue-agency/rogue'
import { finder } from '@medv/finder'
import { History } from 'history'

export const useTracking = (history: History<unknown>) => {
  useEffect(() => {
    initRogueTracking({
      click: (event, comlinkPushParams) => {
        const element = event.currentTarget
        const metadata = {
          tagName: element.tagName,
          label: element.innerText,
          className: element.className,
          selector: finder(element),
        }
        Comlink.push({
          ...comlinkPushParams,
          type: 'manual_activity',
          group: 'video_player',
          action: comlinkPushParams.action || `click_${element.innerText}`,
          metadata,
        })
      },
    })

    Comlink.push({
      type: 'system_activity',
      group: 'video_player',
      action: 'open_page',
      metadata: {
        width: document.documentElement.clientWidth.toString(),
        height: document.documentElement.clientHeight.toString(),
      },
    })

    history.listen(() => {
      Comlink.push({
        type: 'system_activity',
        group: 'video_player',
        action: 'change_page',
      })
    })
  }, [history])
}
