import React from 'react'
import { InterviewRecordingPlayerContainer } from './hooks/useInterviewRecordingPlayer'
import { Controls } from './Controls'
import styled from 'styled-components'
import { InterviewRecordingLayout as _InterviewRecordingLayout } from './InterviewRecordingLayout'
import { UsersArea } from './UsersArea'
import { VideoArea } from './VideoArea'
import { SeekBar } from './SeekBar'
import { InterviewRecordingErrorModal } from './InterviewRecordingErrorModal'
import { InterviewRecordingObject, InterviewPinningObject } from './types'
import { FullScreen } from 'react-full-screen'

type Props = {
  interviewRecording: InterviewRecordingObject
  interviewPinnings: InterviewPinningObject[]
}

const Inner: React.FCX = ({ className }) => {
  const { isError, fullScreenHandle } =
    InterviewRecordingPlayerContainer.useContainer()

  return (
    <Wrapper className={className}>
      <FullScreen handle={fullScreenHandle}>
        <FullscreenContent>
          <InterviewRecordingLayout
            videoArea={<VideoArea />}
            usersArea={<UsersArea />}
          />
          <Bottom>
            <SeekBar />
            <Controls />
          </Bottom>
          <InterviewRecordingErrorModal active={isError} />
        </FullscreenContent>
      </FullScreen>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .fullscreen {
    height: 100%;
    width: 100%;
  }
`
const FullscreenContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${Wrapper} & {
    height: 100%;
  }
`
const InterviewRecordingLayout = styled(_InterviewRecordingLayout)`
  flex: 1;
  height: 100%;
  /* NOTE: Bottomのheight↓ */
  padding-bottom: 36px;
`

const Bottom = styled.div`
  ${Wrapper} & {
    width: 100%;
  }
  position: fixed;
  bottom: 0;
  background: linear-gradient(180deg, #06060a 0%, #2e3253 100%);
  display: flex;
  flex-direction: column;
`

export const InterviewRecordingComponent: React.FCX<Props> = ({
  interviewRecording,
  interviewPinnings,
  className,
}) => (
  <InterviewRecordingPlayerContainer.Provider
    initialState={{ interviewPinnings, interviewRecording }}
  >
    <Inner className={className} />
  </InterviewRecordingPlayerContainer.Provider>
)
