import React from 'react'
import styled from 'styled-components'
import { Txt as _Txt } from '@blue-agency/rogue'

export const ErrorComponent: React.FCX = () => {
  return (
    <Wrapper>
      <Txt>エラーが発生しました</Txt>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Txt = styled(_Txt)`
  text-align: center;
`
